<template>
  <v-list-item>
    <v-list-item-title>
      <a
        href="javascript:void(0);"
        @click="checkBeforeRunTimer()"
        class="black--text"
      >
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-calendar-clock</v-icon
          >{{ language.componentLabel.labelSTAR }}
        </v-card>
      </a>
      <addField
        :path="path"
        :additionalAttributes="additionalAttributes"
        :showDialog="openDialog"
        @dialog-closed="openDialog = false"
      ></addField>

      <confirmationDialog
          v-if="showNoticeDialog"
          :value="showNoticeDialog"
          title="Notice"
          @confirmation-ok="showNoticeDialog = false"
          maxWidth="290"
          message="No Steps are configured for this test. Do you want to add them now ?"
      ></confirmationDialog>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },
  props: ["info", "actionName", "currentTable", "row", "selectedRows"],
  data() {
    return {
      additionalAttributes: {},
      openDialog: false,
      showNoticeDialog: false,
      path: "",
    };
  },

  methods: {
    checkBeforeRunTimer() {
      console.log(this.selectedRows);
      if (Object.keys(this.selectedRows).length == 1) {
        let info = this.selectedRows[Object.keys(this.selectedRows)[0]];
        let n_id = info.n_id;
        let n_id_key = info.n_id_key;
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'post',
          url: this.currentTable.tableUrl,
          data: {
            function: 'checkBeforeRun',
            n_id: n_id,
            n_id_key: n_id_key,
          },
        })
            .then(function (post) {
              console.log("here", post);
              post.data.result.json;
              if (post.data.result.json.result == "true") {
                caller.showExecuteDialog("executeTest");
              } else {
                caller.showNoticeDialog = true;
              }
            })
            .catch((error) => {
              console.log(error);
            });
      } else {
        this.showExecuteDialog("executeSelectedTest");
      }
    },
    showExecuteDialog(toBeRunFunction) {
      this.path = "serve.php?f=testing&f2=testsExecuteApi";
      this.additionalAttributes = {
        function: "getStartTime",
        selected: Object.keys(this.selectedRows),
        toBeRunFunction: toBeRunFunction,
        toBeRunFunctionName: "Run",
      };
      this.openDialog = true;
    },
  },
};
</script>